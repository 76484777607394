import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  Component,
  computed,
  effect,
  EventEmitter,
  forwardRef,
  Injector,
  input,
  Output,
  signal,
} from '@angular/core';

import { NzIconModule } from 'ng-zorro-antd/icon';
import {
  NzTreeFlatDataSource,
  NzTreeFlattener,
  NzTreeViewModule,
} from 'ng-zorro-antd/tree-view';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '../abstract-input.component';
import { AbstractInputArrayComponent } from '../abstract-input-array.component';
import { NzFormatEmitEvent } from 'ng-zorro-antd/tree';

export interface TreeNode {
  name: string;
  key: string;
  disabled?: boolean;
  children?: TreeNode[];
}

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  disabled: boolean;
}
@Component({
  selector: 'xpw-tree-form',

  template: `
    <nz-form-item>
      <nz-form-label *ngIf="label"
        >{{ label() }} {{ isOptional ? ' (Optional)' : '' }}
      </nz-form-label>
      <nz-form-control
        [nzHasFeedback]="hasFeedback()"
        [nzValidateStatus]="errorStatus()"
        [nzErrorTip]="getErrorLabel()"
      >
        <nz-tree-view
          [nzTreeControl]="treeControl"
          [nzDataSource]="dataSource()"
        >
          <nz-tree-node *nzTreeNodeDef="let node" nzTreeNodePadding>
            <nz-tree-node-toggle nzTreeNodeNoopToggle> </nz-tree-node-toggle>
            <nz-tree-node-checkbox
              [nzDisabled]="node.disabled"
              [nzChecked]="checklistSelection.isSelected(node)"
              (nzClick)="leafItemSelectionToggle(node)"
            ></nz-tree-node-checkbox>
            <nz-tree-node-option
              [nzDisabled]="node.disabled"
              (nzClick)="leafItemSelectionToggle(node)"
            >
              {{ node.name }}
            </nz-tree-node-option>
          </nz-tree-node>

          <nz-tree-node
            *nzTreeNodeDef="let node; when: hasChild"
            nzTreeNodePadding
          >
            <nz-tree-node-toggle>
              <span
                nz-icon
                nzType="caret-right"
                nzTheme="fill"
                style="transform: rotate(90deg)"
                nzTreeNodeToggleRotateIcon
              ></span>
            </nz-tree-node-toggle>
            <nz-tree-node-checkbox
              [nzDisabled]="node.disabled"
              [nzChecked]="descendantsAllSelected(node)"
              [nzIndeterminate]="descendantsPartiallySelected(node)"
              (nzClick)="itemSelectionToggle(node)"
            ></nz-tree-node-checkbox>
            <nz-tree-node-option
              [nzDisabled]="node.disabled"
              (nzClick)="itemSelectionToggle(node)"
            >
              {{ node.name }}
            </nz-tree-node-option>
          </nz-tree-node>
        </nz-tree-view>
      </nz-form-control>
    </nz-form-item>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwTreeFormComponent),
      multi: true,
    },
  ],
})
export class XpwTreeFormComponent extends AbstractInputArrayComponent {
  @Output() selectedGroup = new EventEmitter<any[]>();
  nodes = input<any[]>([]);

  label = input<string>('tree');
  hasFeedback = input<boolean>(true);

  firstLoad = true;

  constructor(injector: Injector) {
    super(injector);
    effect(() => {
      this.dataSource().setData(this.nodes());
      this.updateSelectedFromStore();
      this.firstLoad = false;
    });
  }
  private getLastChild(node: any): FlatNode | null {
    if (!node.children) return node;
    return this.getLastChild(node.children);
  }

  private updateSelectedFromStore() {
    let selectedNodes: FlatNode[] = [];
    this.value
      .map((val) => this.getLastChild(val))
      .forEach((node: any) => {
        const _node = this.treeControl.dataNodes.find(
          (item) => item.name == node.name,
        );
        this.treeControl.expand(_node);
        this.itemSelectionToggle(_node);
        selectedNodes.push(_node);
      });
    console.log('selectedNodes', selectedNodes);
    this.checklistSelection.select(...selectedNodes);

    selectedNodes.forEach((node) => {
      let parent = this.getParentNode(node);
      while (parent) {
        this.treeControl.expand(parent);
        parent = this.getParentNode(parent);
      }
    });
  }

  private transformer = (node: TreeNode, level: number): FlatNode => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.name === node.name
        ? existingNode
        : {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level,
            disabled: !!node.disabled,
          };
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  flatNodeMap = new Map<FlatNode, TreeNode>();
  nestedNodeMap = new Map<TreeNode, FlatNode>();
  checklistSelection = new SelectionModel<FlatNode>(true);

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new NzTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children,
  );

  dataSource = signal(
    new NzTreeFlatDataSource(this.treeControl, this.treeFlattener),
  );

  private updateFormValue = () => {
    const filteredNodes = this.checklistSelection.selected.filter((node) => {
      let parent = this.getParentNode(node);
      while (parent) {
        if (this.descendantsAllSelected(parent)) {
          return false;
        }
        parent = this.getParentNode(parent);
      }
      return true;
    });
    let nestedSelectedNodes: any[] = [];

    filteredNodes.map((node) => {
      const nestedNode = this.flatNodeMap.get(node);
      let selectedObj: any = { ...nestedNode, children: null };
      let parent = this.getParentNode(node);

      while (parent) {
        parent = this.getParentNode(node);
        const nestedNode = this.flatNodeMap.get(parent);
        // console.log('parent', parent);
        if (parent) {
          selectedObj = { ...nestedNode, children: selectedObj };
          node = parent; //this.getParentNode(parent);
        }
      }
      nestedSelectedNodes.push(selectedObj);
      console.log('nestedSelectedNodes', nestedSelectedNodes);
    });

    this.value = nestedSelectedNodes;
  };

  hasChild = (_: number, node: FlatNode): boolean => node.expandable;

  descendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return (
      descendants.length > 0 &&
      descendants.every((child) => this.checklistSelection.isSelected(child))
    );
  }

  descendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child),
    );
    return result && !this.descendantsAllSelected(node);
  }

  leafItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  itemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  checkAllParentsSelection(node: FlatNode): void {
    let parent: FlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
    if (!this.firstLoad) {
      this.updateFormValue();
    }
  }

  checkRootNodeSelection(node: FlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => this.checklistSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: FlatNode): FlatNode | null {
    const currentLevel = node.level;

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
}
