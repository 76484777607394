import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  inject,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Role } from '@features/user/user-store/user.interface';
import { ErrorKey } from '@shared/utility/errors-keys-msg';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Subscription } from 'rxjs';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';

@Component({
  template: ``,
})
export class BaseDrawerTemplateComponent {
  //implements OnDestroy {
  @ViewChild('componentTemplate') componentTemplate: TemplateRef<{
    drawerRef: NzDrawerRef<string>;
  }>;
  role = Role;
  xpwForm: FormGroup;
  Subscriptions$: Subscription[] = [];
  confirmService = inject(ConfirmDialogService);
  handleServerErrors(
    ValidationErrors: HttpErrorResponse,
    callback?: () => void,
  ) {
    console.log('handleErrors', ValidationErrors);
    if (ValidationErrors.error) {
      const errors = ValidationErrors.error.validationErrorKeys;

      Object.keys(errors).forEach((key) => {
        let controlErrors: { [key: string]: boolean } = {};
        errors[key].map((errorK) => {
          const errorKey = errorK;
          console.log('errorKey', errorKey.split('$'));
          controlErrors[ErrorKey[errorKey]] = true;
        });
        if (key.includes('organizationRequests')) key = key + '.frequency';
        if (key.includes('parameters')) key = key + '.value';
        this.xpwForm?.get(key).setErrors(controlErrors);
      });
    }

    if (callback) {
      callback();
    }
  }

  closeDrawer(ref: any) {
    this.onClose().then((res) => {
      if (res) ref.close();
    });
  }

  onClose = (): Promise<boolean> => {
    return new Promise((resolve) => {
      if (this.xpwForm && this.xpwForm.dirty) {
        this.confirmService.showConfirmModal({
          title: $localize`Unsaved Changes`,
          content: $localize`You have unsaved changes in your data. 
                    <br> Closing this panel will discard all modifications. 
                    <br> <br> Are you sure you want to proceed?`,
          okText: $localize`Discard Changes`,
          cancelText: $localize`Cancel`,
          auditCommentsVisible: false,
          okButtonIsRed: true,
          handleOk: () => {
            resolve(true);
          },
          handleCancel: () => {
            resolve(false);
          },
        });
      } else {
        resolve(true);
      }
    });
  };

  checkErrorsFromForm(listOfKeys: string[]): boolean {
    return listOfKeys.some((key) => this.xpwForm.get(key)?.errors != null);
  }
}
