import {
  Component,
  Input,
  ChangeDetectionStrategy,
  forwardRef,
  Injector,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { differenceInCalendarDays } from 'date-fns';
import { DateManagerService } from '@core/services/date-manager/date-manager.service';
import { AbstractDateTimeInputComponent } from '../abstract-datetime.component';

@Component({
  selector: 'xpw-form-date-range',
  template: `
    <nz-form-item>
      <nz-form-label
        >{{ label }} {{ isOptional ? ' (Optional)' : '' }}
      </nz-form-label>
      <nz-form-control
        [nzHasFeedback]="true"
        [nzValidateStatus]="errorStatus()"
        [nzErrorTip]="getErrorLabel()"
      >
        <nz-range-picker
          style="width: 100%"
          [(ngModel)]="value"
          [nzFormat]="'M/dd/yyyy'"
          [nzDisabledDate]="disabledDate"
          (nzOnCalendarChange)="calanderChange($event)"
        ></nz-range-picker>
        <ng-content></ng-content>
      </nz-form-control>
    </nz-form-item>
  `,
  // styleUrls: ['./xpw-form-input-date.component.less'],

  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwDateRangeComponent),
      multi: true,
    },
  ],
})
export class XpwDateRangeComponent extends AbstractDateTimeInputComponent {
  @Input('label') label: string;
  @Input() showDateTime: boolean = false;
  dateFormat: string;
  constructor(
    private dateService: DateManagerService,
    injector: Injector,
  ) {
    super(injector);
    this.dateFormat = this.format;
  }
  private today = this.dateService.getOnlyDate(new Date());

  get format(): string {
    console.log(' get format()', this.dateService.datePickerFormat);
    if (this.showDateTime) {
      return this.dateService.dateTimePickerFormat;
    } else {
      return this.dateService.datePickerFormat;
    }
  }

  calanderChange = (value: any): void => {
    this.onChange(this.dateService.getOnlyDate(value));
  };

  // Can not select days before today and today
  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) > 0;
}
