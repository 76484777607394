import { Role } from '@features/user/user-store/user.interface';

export interface menu {
  id?: number;
  title: string;
  url?: string;
  children?: menu[];
  icon?: string;
  selected?: boolean;
  open?: boolean;
  viewForRole?: Role[];
  disabled?: boolean;
}
export enum RoutingURL {
  electricity = 'electricity',
  transformers = 'transformers',
  water = 'water',
  waterOverview = 'dashboard/water-overview',
  customerService = 'customer-service',
  anomaliesOutliers = 'anomalies&outliers',
  management = 'management',
  reports = 'management/report-list',
  users = 'management/user-list',
  departments = 'management/departments-list',
  organizations = 'management/organization-list',
  meters = 'management/meter-list',
  meterGroups = 'management/meter-group-list',
  importHistory = 'management/import-history-list',
  energyOverview = 'electricity/energy-overview',
  demandOverview = 'electricity/demand-overview',
  energyDistribution = 'electricity/energy-distribution',
  validationOverview = 'dashboard/validation-overview',
  validationDetails = 'dashboard/validation-details',
  eventOverview = 'dashboard/event-overview',
  eventLog = 'dashboard/event-log',
  voltages = 'dashboard/voltages',
  events = 'dashboard/events',
  energyDemands = 'dashboard/energy-&-demands',
  userProfile = 'user-profile/current-organization',
  notFound = '404',
}
export const Navigation: menu[] = [
  {
    id: 1,
    title: $localize`Electricity`,
    icon: 'electricity',

    open: false,
    children: [
      {
        title: $localize`Energy Overview`,
        url: 'dashboard/energy-overview', //RoutingURL.energyOverview,
        selected: false,
        icon: 'ellipse',
        disabled: false,
      },
      {
        title: $localize`Demand Overview`,
        url: 'dashboard/demand-overview', //RoutingURL.demandOverview,
        selected: false,
        icon: 'ellipse',
        disabled: false,
      },
      {
        title: $localize`Energy Distribution`,
        url: null,
        selected: false,
        icon: 'ellipse',
        disabled: true,
      },
    ],
  },
  {
    id: 2,
    title: $localize`Transformers`,
    icon: 'transformers',
    open: false,

    children: [
      {
        title: $localize`Transformer Load`,
        icon: 'ellipse',
        url: null,
        disabled: true,
      },
    ],
  },
  {
    id: 3,
    title: $localize`Water`,
    icon: 'water',
    open: false,
    children: [
      {
        title: $localize`Overview`,
        icon: 'ellipse',
        url: RoutingURL.waterOverview,
        disabled: false,
      },
    ],
  },
  {
    id: 4,
    title: $localize`Customer Service`,
    icon: 'customer-service',
    open: false,
    children: [
      {
        title: $localize`Energy & Demands`,
        icon: 'ellipse',
        url: RoutingURL.energyDemands,
        disabled: false,
      },
      {
        title: $localize`Voltages`,
        icon: 'ellipse',
        url: RoutingURL.voltages,
        disabled: false,
      },
      {
        title: $localize`Events`,
        icon: 'ellipse',
        url: RoutingURL.events,
        disabled: false,
      },
    ],
  },
  {
    id: 5,
    title: $localize`Anomalies & Outliers`,
    icon: 'anomalies',
    open: false,
    children: [
      {
        title: $localize`Validation Overview`,
        icon: 'ellipse',
        url: RoutingURL.validationOverview,
      },
      {
        title: $localize`Validation Details`,
        icon: 'ellipse',
        url: RoutingURL.validationDetails,
        disabled: true,
      },
      {
        title: $localize`Event Overview`,
        icon: 'ellipse',
        url: RoutingURL.eventOverview,
        disabled: false,
      },
      {
        title: $localize`Event Log`,
        icon: 'ellipse',
        url: RoutingURL.eventLog,
        disabled: false,
      },
    ],
  },
  {
    id: 6,
    title: $localize`Management`,
    icon: 'management',
    open: false,
    viewForRole: [
      Role.SysAdmin,
      Role.DepartmentAdmin,
      Role.PermissionsAdmin,
      Role.DeviceAdmin,
      Role.DataAdmin,
    ],
    children: [
      {
        title: $localize`Users`,
        icon: 'username',
        url: RoutingURL.users,
        selected: false,
        viewForRole: [
          Role.SysAdmin,
          Role.DepartmentAdmin,
          Role.PermissionsAdmin,
        ],
      },
      {
        title: $localize`Departments`,
        icon: 'departments',
        url: RoutingURL.departments,
        selected: false,
        viewForRole: [Role.SysAdmin],
      },
      {
        title: $localize`Organizations`,
        icon: 'organizations',
        url: RoutingURL.organizations,
        selected: false,
        viewForRole: [Role.SysAdmin, Role.DepartmentAdmin],
      },
      {
        title: $localize`Meters`,
        icon: 'site',
        url: RoutingURL.meters,
        selected: false,
        viewForRole: [Role.SysAdmin, Role.DepartmentAdmin, Role.DeviceAdmin],
      },
      {
        title: $localize`Groups`,
        icon: 'site',
        url: RoutingURL.meterGroups,
        selected: false,
        viewForRole: [Role.SysAdmin, Role.DepartmentAdmin, Role.DeviceAdmin],
      },
      {
        title: $localize`Import History`,
        icon: 'site',
        url: RoutingURL.importHistory,
        selected: false,
        viewForRole: [
          Role.SysAdmin,
          Role.DepartmentAdmin,
          Role.DeviceAdmin,
          Role.DataAdmin,
        ],
      },
      {
        title: $localize`Reports`,
        icon: 'site',
        url: RoutingURL.reports,
        selected: false,
      },
    ],
  },
];
