export const CountriesTitle: EnumTitleType = {
  1: $localize`Afghanistan`,
  2: $localize`Albania`,
  3: $localize`Algeria`,
  4: $localize`American Samoa`,
  5: $localize`Andorra`,
  6: $localize`Angola`,
  7: $localize`Anguilla`,
  8: $localize`Antarctica`,
  9: $localize`Argentina`,
  10: $localize`Armenia`,
  11: $localize`Aruba`,
  12: $localize`Australia`,
  13: $localize`Austria`,
  14: $localize`Azerbaijan`,
  15: $localize`Bahamas`,
  16: $localize`Bahrain`,
  17: $localize`Bangladesh`,
  18: $localize`Barbados`,
  19: $localize`Belarus`,
  20: $localize`Belgium`,
  21: $localize`Belize`,
  22: $localize`Benin`,
  23: $localize`Bermuda`,
  24: $localize`Bhutan`,
  25: $localize`Bolivia`,
  26: $localize`Bosnia and Herzegovina`,
  27: $localize`Botswana`,
  28: $localize`Brazil`,
  29: $localize`Brunei`,
  30: $localize`Bulgaria`,
  31: $localize`Burkina Faso`,
  32: $localize`Burma (Myanmar)`,
  33: $localize`Burundi`,
  34: $localize`Cambodia`,
  35: $localize`Cameroon`,
  36: $localize`Canada`,
  37: $localize`Cape Verde`,
  38: $localize`Cayman Islands`,
  39: $localize`Central African Republic`,
  40: $localize`Chad`,
  41: $localize`Chile`,
  42: $localize`China`,
  43: $localize`Colombia`,
  44: $localize`Comoros`,
  45: $localize`Cook Islands`,
  46: $localize`Costa Rica`,
  47: $localize`Croatia`,
  48: $localize`Cuba`,
  49: $localize`Cyprus`,
  50: $localize`Czech Republic`,
  51: $localize`Democratic Republic of the Congo`,
  52: $localize`Denmark`,
  53: $localize`Djibouti`,
  54: $localize`Dominica`,
  55: $localize`Dominican Republic`,
  56: $localize`Ecuador`,
  57: $localize`Egypt`,
  58: $localize`El Salvador`,
  59: $localize`Equatorial Guinea`,
  60: $localize`Eritrea`,
  61: $localize`Estonia`,
  62: $localize`Ethiopia`,
  63: $localize`Falkland Islands`,
  64: $localize`Faroe Islands`,
  65: $localize`Fiji`,
  66: $localize`Finland`,
  67: $localize`France`,
  68: $localize`French Polynesia`,
  69: $localize`Gabon`,
  70: $localize`Gambia`,
  71: $localize`Gaza Strip`,
  72: $localize`Georgia`,
  73: $localize`Germany`,
  74: $localize`Ghana`,
  75: $localize`Gibraltar`,
  76: $localize`Greece`,
  77: $localize`Greenland`,
  78: $localize`Grenada`,
  79: $localize`Guam`,
  80: $localize`Guatemala`,
  81: $localize`Guinea`,
  82: $localize`Guinea-Bissau`,
  83: $localize`Guyana`,
  84: $localize`Haiti`,
  85: $localize`Honduras`,
  86: $localize`Hong Kong`,
  87: $localize`Hungary`,
  88: $localize`Iceland`,
  89: $localize`India`,
  90: $localize`Indonesia`,
  91: $localize`Iran`,
  92: $localize`Iraq`,
  93: $localize`Ireland`,
  94: $localize`Isle of Man`,
  95: $localize`Israel`,
  96: $localize`Italy`,
  97: $localize`Ivory Coast`,
  98: $localize`Jamaica`,
  99: $localize`Japan`,
  100: $localize`Jordan`,
  101: $localize`Kazakhstan`,
  102: $localize`Kenya`,
  103: $localize`Kiribati`,
  104: $localize`Kosovo`,
  105: $localize`Kuwait`,
  106: $localize`Kyrgyzstan`,
  107: $localize`Laos`,
  108: $localize`Latvia`,
  109: $localize`Lebanon`,
  110: $localize`Lesotho`,
  111: $localize`Liberia`,
  112: $localize`Libya`,
  113: $localize`Liechtenstein`,
  114: $localize`Lithuania`,
  115: $localize`Luxembourg`,
  116: $localize`Macau`,
  117: $localize`Macedonia`,
  118: $localize`Madagascar`,
  119: $localize`Malawi`,
  120: $localize`Malaysia`,
  121: $localize`Maldives`,
  122: $localize`Mali`,
  123: $localize`Malta`,
  124: $localize`Mauritania`,
  125: $localize`Mauritius`,
  126: $localize`Mexico`,
  127: $localize`Micronesia`,
  128: $localize`Moldova`,
  129: $localize`Monaco`,
  130: $localize`Mongolia`,
  131: $localize`Montenegro`,
  132: $localize`Montserrat`,
  133: $localize`Morocco`,
  134: $localize`Mozambique`,
  135: $localize`Namibia`,
  136: $localize`Nauru`,
  137: $localize`Nepal`,
  138: $localize`Netherlands`,
  139: $localize`Netherlands Antilles`,
  140: $localize`New Caledonia`,
  141: $localize`New Zealand`,
  142: $localize`Nicaragua`,
  143: $localize`Niger`,
  144: $localize`Nigeria`,
  145: $localize`North Korea`,
  146: $localize`Norway`,
  147: $localize`Oman`,
  148: $localize`Pakistan`,
  149: $localize`Panama`,
  150: $localize`Papua New Guinea`,
  151: $localize`Paraguay`,
  152: $localize`Peru`,
  153: $localize`Philippines`,
  154: $localize`Poland`,
  155: $localize`Portugal`,
  156: $localize`Puerto Rico`,
  157: $localize`Qatar`,
  158: $localize`Republic of the Congo`,
  159: $localize`Romania`,
  160: $localize`Russia`,
  161: $localize`Rwanda`,
  162: $localize`Saint Kitts and Nevis`,
  163: $localize`Saint Lucia`,
  164: $localize`Samoa`,
  165: $localize`Saudi Arabia`,
  166: $localize`Senegal`,
  167: $localize`Serbia`,
  168: $localize`Seychelles`,
  169: $localize`Sierra Leone`,
  170: $localize`Singapore`,
  171: $localize`Slovakia`,
  172: $localize`Slovenia`,
  173: $localize`Somalia`,
  174: $localize`South Africa`,
  175: $localize`South Korea`,
  176: $localize`Spain`,
  177: $localize`Sri Lanka`,
  178: $localize`Sudan`,
  179: $localize`Suriname`,
  180: $localize`Swaziland`,
  181: $localize`Sweden`,
  182: $localize`Switzerland`,
  183: $localize`Syria`,
  184: $localize`Taiwan`,
  185: $localize`Tajikistan`,
  186: $localize`Tanzania`,
  187: $localize`Thailand`,
  188: $localize`Togo`,
  189: $localize`Tonga`,
  190: $localize`Trinidad and Tobago`,
  191: $localize`Tunisia`,
  192: $localize`Turkey`,
  193: $localize`Turkmenistan`,
  194: $localize`Uganda`,
  195: $localize`Ukraine`,
  196: $localize`United Arab Emirates`,
  197: $localize`United Kingdom`,
  198: $localize`United States`,
  199: $localize`Uruguay`,
  200: $localize`Uzbekistan`,
  201: $localize`Vanuatu`,
  202: $localize`Venezuela`,
  203: $localize`Vietnam`,
  204: $localize`Yemen`,
  205: $localize`Zambia`,
  206: $localize`Zimbabwe`,
};

export interface EnumTitleType {
  [key: number]: string;
}

export const LanguageTitle: EnumTitleType = {
  1: $localize`English (US)`,
};

export const RoleTitle: EnumTitleType = {
  1: $localize`System Admin`,
  2: $localize`Permissions Admin`,
  3: $localize`Department Admin`,
  5: $localize`Device Admin`,
  6: $localize`Data Admin`,
  20: $localize`Viewer`,
};

export const AdminRoleTitle: EnumTitleType = {
  // 1: $localize`System Admin`,
  2: $localize`Permissions`,
  3: $localize`Department`,
  5: $localize`Device`,
  6: $localize`Data`,
};

export const AccountStatusTitle: EnumTitleType = {
  1: $localize`Active`,
  2: $localize`Inactive`,
  3: $localize`Pending`,
  4: $localize`Suspended`,
  5: $localize`Locked`,
  6: $localize`Expired`,
  10: $localize`Deleted`,
};

export const OrganizationStatusTitle = {
  1: $localize`Active`,
  3: $localize`Deactivated`,
  4: $localize`Deleted`,
};

export const FileImportTypeTitle: EnumTitleType = {
  1: $localize`Insert Meters`,
  2: $localize`Update Meters`,
  3: $localize`Consumption`,
};

export const TransformerTypeTitle: EnumTitleType = {
  1: $localize`Single`,
  2: $localize`Three Phase`,
};

export const MeterStatusTitle: EnumTitleType = {
  1: $localize`Active`,
  2: $localize`Suspended`,
  3: $localize`Stopped`,
  4: $localize`Deleted`,
};

export const MeasuringPointTypeTitle: EnumTitleType = {
  0: $localize`None`,
  1: $localize`Consumer`,
  2: $localize`Consumer Gross`,
  3: $localize`Transformer`,
  4: $localize`Grid Connection Point`,
  5: $localize`Generator`,
  6: $localize`PV producer`,
  7: $localize`Solar Producer`,
  8: $localize`Wind Producer`,
  9: $localize`EV charger`,
  10: $localize`BESS`,
};

export const OrganisationRequestTypeTitle: EnumTitleType = {
  1: $localize`Electricity Interval Data`,
  2: $localize`Electricity Registers Data`,
  3: $localize`Electricity Measurements Data`,
  4: $localize`Electricity Events Data`,
  5: $localize`Water Interval Data`,
};

export const DataSourceTypeTitle: EnumTitleType = {
  1: $localize`Gefen API`,
  2: $localize`OpenWay API`,
  4: $localize`IID FileImport`,
  5: $localize`XPW API`,
};

export const UtilityServiceTitle: EnumTitleType = {
  0: $localize`none`,
  1: $localize`Electricity`,
  2: $localize`Water`,
  3: $localize`Gas`,
  4: $localize`Fuel`,
  5: $localize`Cooling Data`,
  6: $localize`Compressed Air`,
  7: $localize`Thermal Oil`,
  8: $localize`Steam`,
  9: $localize`Wastewater`,
  10: $localize`HVAC`,
};

export const ImportTypeTitle: EnumTitleType = {
  1: $localize`Bulk Meter Creation`,
  2: $localize`Bulk Meter Update`,
  3: $localize`Consumption`,
};

/* enumTitleName: 'OrganizationStatusTitle'


import *  as GlobalTitles from ...


GlobalTitles[enumTitleName][key] */
