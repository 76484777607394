// src/app/Department/state/Department.effects.ts

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as DepartmentActions from './departments.actions';

import { DepartmentsService } from '@core/api/departments.service';

import { NotificationService } from '@core/services/notifications/notifications.service';
import { updateAccessObjects } from 'src/app/store/current-user/current-user.actions';
import { IDepartment } from './departments.interface';

@Injectable()
export class DepartmentEffects {
  constructor(
    private actions$: Actions,
    private apiService: DepartmentsService,
    private notification: NotificationService,
  ) {}

  getDepartmentList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentActions.getDepartmentList),
      mergeMap(() =>
        this.apiService.getDepartments().pipe(
          mergeMap((Departments) =>
            of(
              DepartmentActions.getDepartmentListSuccess({ Departments }),
              updateAccessObjects({
                departments: Departments,
                meterGroups: null,
              }),
            ),
          ),
          catchError((error) =>
            of(DepartmentActions.showErrors({ message: error })),
          ),
        ),
      ),
    ),
  );

  getStorageList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentActions.selectDepartment),

      mergeMap(() =>
        this.apiService.getListOfStorage().pipe(
          map((storageList) =>
            DepartmentActions.getStorageListSuccess({ storageList }),
          ),
          catchError((error) =>
            of(DepartmentActions.showErrors({ message: error })),
          ),
        ),
      ),
    ),
  );

  getStorageListForNewform$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentActions.createNewDepartment),

      mergeMap(() =>
        this.apiService.getListOfStorage().pipe(
          map((storageList) =>
            DepartmentActions.getStorageListSuccess({ storageList }),
          ),
          catchError((error) =>
            of(DepartmentActions.showErrors({ message: error })),
          ),
        ),
      ),
    ),
  );

  updateDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentActions.updateDepartment),
      mergeMap((action) =>
        this.apiService.updateDepartment(action.departmentUpdated).pipe(
          mergeMap((result: IDepartment) => {
            const updatedDepartment: IDepartment = {
              ...result,
            };
            return of(
              DepartmentActions.updateDepartmentSuccess({
                updatedDepartment,
              }),
            );
          }),
          catchError((error) =>
            of(
              DepartmentActions.updateDepartmentFailure({
                ValidationErrors: error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  updateDepartmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentActions.updateDepartmentSuccess),
      mergeMap(() =>
        of(
          DepartmentActions.showSuccessMessage({
            message: 'Department updated',
          }),
        ),
      ),
    ),
  );

  setNewDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentActions.setNewDepartment),
      mergeMap((action) =>
        this.apiService.addDepartment(action.departmentUpdated).pipe(
          mergeMap((result) => {
            const createdDepartment: IDepartment = {
              ...action.departmentUpdated,
              departmentUID: result,
            };
            return of(
              DepartmentActions.setNewDepartmentSuccess({
                createdDepartment,
              }),
            );
          }),
          catchError((error) =>
            of(
              DepartmentActions.setNewDepartmentFailure({
                ValidationErrors: error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  setNewDepartmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentActions.setNewDepartmentSuccess),
      mergeMap((action) =>
        of(
          DepartmentActions.showSuccessMessage({
            message: `Department ${action.createdDepartment.departmentName} created`,
          }),
        ),
      ),
    ),
  );

  deleteDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentActions.deleteDepartment),
      mergeMap((action) =>
        this.apiService.deleteDepartment(action.departmentUID).pipe(
          mergeMap((result) =>
            of(
              DepartmentActions.deleteDepartmentSuccess({
                departmentUID: action.departmentUID,
              }),
            ),
          ),
          catchError((error) =>
            of(
              DepartmentActions.deleteDepartmentFailure({
                ValidationErrors: error?.additionalData,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  deleteDepartmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DepartmentActions.deleteDepartmentSuccess),
      mergeMap(() =>
        of(
          DepartmentActions.showSuccessMessage({
            message: 'Department deleted',
          }),
        ),
      ),
    ),
  );

  showErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DepartmentActions.showErrors),
        tap((action) => {
          // this.notification.error(action.message);
        }),
      ),
    { dispatch: false },
  );

  showSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DepartmentActions.showSuccessMessage),
        tap((action) => {
          this.notification.success(undefined, action.message);
        }),
      ),
    { dispatch: false },
  );
}
