// src/app/MeterGroup/state/MeterGroup.effects.ts

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import * as MeterGroupActions from './meter-group.actions';
import { MeterGroupsService } from '@core/api/meter-group.service';
import { NotificationService } from '@core/services/notifications/notifications.service';
import {
  getAccessObjects,
  updateAccessObjects,
  UserActions,
} from 'src/app/store/current-user/current-user.actions';
import { selectMeterNameList } from '@features/meters/store/meters.selector';
import { Store } from '@ngrx/store';
import {
  selectMeterUIDsToAdd,
  selectMeterUIDsToRemove,
  selectSelectedMeterGroup,
} from './meter-group.selector';
import {
  resetSelectMeters,
  updateSelectedMetersSuccess,
} from '@features/select-meters/store/select-meters.actions';

@Injectable()
export class MeterGroupEffects {
  constructor(
    private actions$: Actions,
    private apiService: MeterGroupsService,
    private notification: NotificationService,
    private store: Store,
  ) {}

  getGroupList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterGroupActions.getGroupList),
      mergeMap((action) =>
        this.apiService.getMeterGroups().pipe(
          mergeMap((meterGroups) =>
            of(
              MeterGroupActions.getGroupListSuccess({ meterGroups }),
              updateAccessObjects({
                departments: null,
                meterGroups: meterGroups,
              }),
            ),
          ),
          catchError((error) =>
            of(MeterGroupActions.showErrors({ message: error })),
          ),
        ),
      ),
    ),
  );

  // getMetersList$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(MeterGroupActions.selectMeterGroup),
  //     mergeMap((action) =>
  //       this.apiService.getMetersList(action.meterGroupUID).pipe(
  //         map((meterUIDs) =>
  //           MeterGroupActions.getMetersListSuccess({ meterUIDs }),
  //         ),
  //         catchError((error) =>
  //           of(MeterGroupActions.showErrors({ message: error })),
  //         ),
  //       ),
  //     ),
  //   ),
  // );

  updateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterGroupActions.updateGroup),
      mergeMap((action) =>
        this.apiService.updateMeterGroup(action.MeterGroupUpdated).pipe(
          mergeMap(() =>
            of(
              MeterGroupActions.showMetersGroupSuccessMessage({
                message: 'Meter Group updated',
              }),
              MeterGroupActions.updateGroupSuccess(),
              MeterGroupActions.setLastChangedCreatedMeterGroup({
                meterGroup: action.MeterGroupUpdated,
              }),
              MeterGroupActions.getGroupList(),
            ),
          ),
          catchError((ValidationErrors) =>
            of(MeterGroupActions.validationError({ ValidationErrors })),
          ),
        ),
      ),
    ),
  );

  createNewGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterGroupActions.createNewGroup),
      mergeMap((action) =>
        this.apiService.addMeterGroup(action.MeterGroupUpdated).pipe(
          mergeMap((result) =>
            of(
              MeterGroupActions.showMetersGroupSuccessMessage({
                message: 'Meter Group created',
              }),
              MeterGroupActions.createNewGroupSuccess({
                MeterGroupCreated: result,
              }),
              MeterGroupActions.setLastChangedCreatedMeterGroup({
                meterGroup: {
                  ...action.MeterGroupUpdated,
                  meterGroupUID: result.meterGroupUID,
                },
              }),
              MeterGroupActions.getGroupList(),
              MeterGroupActions.setSelectedGroup({
                meterGroupUID: result.meterGroupUID,
                newMeterGroup: true,
              }),
            ),
          ),
          catchError((ValidationErrors) =>
            of(MeterGroupActions.validationError({ ValidationErrors })),
          ),
        ),
      ),
    ),
  );

  deleteGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterGroupActions.deleteGroup),
      mergeMap((action) =>
        this.apiService.deleteMeterGroup(action.meterGroupUID).pipe(
          mergeMap(() =>
            of(
              MeterGroupActions.showMetersGroupSuccessMessage({
                message: 'Meter Group deleted',
              }),
              MeterGroupActions.getGroupList(),
            ),
          ),
          catchError((error) =>
            of(MeterGroupActions.showErrors({ message: error })),
          ),
        ),
      ),
    ),
  );

  showErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeterGroupActions.showErrors),
        tap((action) => {
          // this.notification.error(action.message);
        }),
      ),
    { dispatch: false },
  );

  showSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeterGroupActions.showMetersGroupSuccessMessage),
        tap((action) => {
          this.notification.success(undefined, action.message);
        }),
      ),
    { dispatch: false },
  );

  updateMeterGroupMetersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterGroupActions.updateMeterGroupMetersList),
      tap(() => this.notification.info(undefined, 'Updating meters list...')),
      withLatestFrom(
        this.store.select(selectSelectedMeterGroup),
        this.store.select(selectMeterUIDsToRemove),
      ),

      mergeMap(([action, selectedMeterGroup]) =>
        this.apiService
          .updateMeterGroupMetersList(
            selectedMeterGroup.meterGroupUID,
            selectedMeterGroup.meterUIDsToAdd,
            selectedMeterGroup.meterUIDsToRemove,
          )
          .pipe(
            mergeMap((response) =>
              of(
                MeterGroupActions.updateMeterGroupMetersListSuccess({
                  response: response,
                }),
                // MeterGroupActions.getGroupList(),
              ),
            ),
            catchError((error) =>
              of(MeterGroupActions.showErrors({ message: error })),
            ),
          ),
      ),
    ),
  );

  updateMeterGroupMetersListSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeterGroupActions.updateMeterGroupMetersListSuccess),
        tap(({ response }) => {
          if (response.metersAdded) {
            this.notification.success(
              undefined,
              `${response.metersAdded} meters added`,
            );
          }
          if (response.metersRemoved) {
            this.notification.success(
              undefined,
              `${response.metersRemoved} meters removed`,
            );
          }
        }),
        tap(() => of(this.store.dispatch(updateSelectedMetersSuccess()))),
      ),
    { dispatch: false },
  );
}
