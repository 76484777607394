import { ICurrentUser } from './current-user.interface';
import {
  AccountStatus,
  UserProfile,
} from '@features/user/user-store/user.interface';

export const initialState: ICurrentUser = {
  loginData: {
    isLoggedIn: false,
    message: 'Please wait a moment while we load your personal account',
    token: '',
    organizations: [],
    loading: true,
  },
  userUID: '',
  azureUserID: '',
  userName: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  mobilePhone: '',
  languageID: 0,
  defaultPageID: 0,
  userSourceID: 0,
  userProfileID: UserProfile.Regular,
  loginIdentifierTypeID: 0,
  accountStatusID: AccountStatus.Pending,
  expirationDate: new Date(),
  createDate: new Date(),
  createuserUID: '',
  createUserName: '0',
  currentDepartmentUID: null,
  // currentOrganizationUID: null,
  organizations: [],
  hasAssignedDepartment: false,
  roles: [],
  // accessObjects: [],
  departmentUIDs: [],
  meterGroupUIDs: [],
  allGroupsAssigned: false,
  organizationName: 'organization name',
  accessObjects: { departments: [], meterGroups: [], meterCount: 0 },
  currentOrganization: {
    uid: '',
    organizationName: '',
    icon: null,
    location: {
      country: { id: 0, name: '', hasStates: false },
      state: { id: 0, name: '' },
      city: '',
    },
  },
  logoImage: null,
};
