import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map, throwError, shareReplay, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IPagingRequest, IFilterOptions } from '@shared/utility/global-enums';
import {
  IMeter,
  IMeterReplacement,
  MeasuringPointType,
  MeterName,
  MeterStatus,
  MeterNameList,
} from '@features/meters/store/meters.interface';
import { SelectMetersService } from '@features/select-meters/service/select-meters.service';

@Injectable({
  providedIn: 'root',
})
export class MetersService implements SelectMetersService {
  apiBaseUrl = '';
  endpoint = 'organizations';

  private meterFiltersCache$: Observable<any>;

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  getMetersList(
    pagingRequest: IPagingRequest,
    filter: any,
    search: string,
    isExport: boolean = false,
    options?: any,
  ): Observable<any> {
    return this.http.get<any>(`${this.apiBaseUrl}${this.endpoint}/meters`, {
      params: {
        PageNumber: pagingRequest.pageNumber,
        PageSize: pagingRequest.pageSize,
        OrderBy: pagingRequest.orderBy,
        IsDescending: pagingRequest.isDescending,
        MeterUID: filter?.MeterUID,
        MeterGroupUID: filter?.MeterGroupUID,
        UtilityServiceId: filter?.UtilityServiceId,
        MeasuringPointTypeId: filter?.MeasuringPointTypeId,
        PowerSourceUID: filter?.PowerSourceUID,
        ChannelConfigurationUID: filter?.ChannelConfigurationUID,
        IsVirtual: filter?.IsVirtual,
        IsOppositeConfiguration: filter?.IsOppositeConfiguration,
        StateId: filter?.StateId,
        City: filter?.City,
        StatusID: filter?.StatusID,
        search: search,
        isExport: isExport,
      },
      ...options,
    });
  }
  getMeters(
    paging: any,
    search: string,
    skipMeterGroupUID: string = null,
  ): Observable<any[]> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/name`,
      {
        params: {
          pageNumber: paging.pageNumber,
          pageSize: paging.pageSize,
          orderBy: paging.orderBy,
          isDescending: paging.isDescending,
          search: search,
          skipMeterGroupUID: skipMeterGroupUID,
        },
      },
    );
  }
  getMeterNameList(): Observable<MeterNameList> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/name`,
      {},
    );
  }
  // cached filter refactor to globaly remove cache when changing organization
  // getMeterFilters(): Observable<any> {
  //   if (!this.meterFiltersCache$) {
  //     this.meterFiltersCache$ = this.http
  //       .get<any>(`${this.apiBaseUrl}${this.endpoint}/meters/filters`, {})
  //       .pipe(
  //         shareReplay(1),
  //         catchError((error) => {
  //           this.meterFiltersCache$ = null;
  //           return throwError(error);
  //         }),
  //       );
  //   }
  //   return this.meterFiltersCache$;
  // }
  getMeterFilters(): Observable<any> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/filters`,
      {},
    );
  }

  changeStatus(meterUID: string, status: MeterStatus): Observable<any> {
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}/status`,
      { meterStatusID: status },
    );
  }

  deleteMeter(meterUID: string): Observable<any> {
    return this.http.delete<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}`,
    );
  }

  createMeter(meterDetails: IMeter): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}${this.endpoint}/meters`, {
      meterGeneralInfo: meterDetails.meterGeneralInfo,
      currentReplacement: meterDetails.currentReplacement,
      transformerSetting: meterDetails.transformerSetting,
      groupUIDs: meterDetails.groupUIDs,
    });
  }

  updateMeter(meterDetails: IMeter): Observable<any> {
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterDetails.meterUID}`,
      {
        internalMeterID: meterDetails.internalMeterID,
        meterGeneralInfo: meterDetails.meterGeneralInfo,
        currentReplacement: meterDetails.currentReplacement,
        transformerSetting: meterDetails.transformerSetting,
        groupUIDs: meterDetails.groupUIDs,
      },
    );
  }

  getReplacementHistory(meterUID: string): Observable<IMeterReplacement[]> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}/replacement/history`,
      {},
    );
  }

  createReplacement(
    meterUID: string,
    replacement: IMeterReplacement,
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}/replacement`,
      replacement,
    );
  }

  updateReplacement(
    meterUID: string,
    replacement: IMeterReplacement,
  ): Observable<any> {
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}/replacement/${replacement.meterReplacementUID}`,
      replacement,
    );
  }

  deleteReplacement(
    meterUID: string,
    meterReplacementUID: string,
  ): Observable<any> {
    return this.http.delete<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}/replacement/${meterReplacementUID}`,
    );
  }
}
