import { createAction, props } from '@ngrx/store';

import {
  DASHBOARD_PAGE,
  DASHBOARD_URL,
  FilterParam,
  IDashboardPage,
  IEmbedParamsResponse,
} from './dashboard.interface';

export enum DashboardActionTypes {
  GetEmbedParams = '[Dashboards] Get Embed Params',
  GetEmbedParamsSuccess = '[Dashboards] Get Embed Params Success',

  SetOpenDashboardFilter = '[Dashboard] Set Open Dashboard Filter',
  DashboardError = '[Dashboard] Error',
  ShowErrorMessage = '[Dashboard] Show Errors Messages',

  SetFilters = '[Dashboard] Set Filters',
  SetFiltersSuccess = '[Dashboard] Set Filters Success',
  SetFiltersError = '[Dashboard] Set Filters Error',

  SetClearFilterHeader = '[Dashboard] Set Clear Filter Header',

  setSelectedDashboard = '[Dashboard] Set Selected Dashboard',
  SetActivePage = '[Dashboard] Set Active Page',
}

export const getEmbedParams = createAction(DashboardActionTypes.GetEmbedParams);

export const getEmbedParamsSuccess = createAction(
  DashboardActionTypes.GetEmbedParamsSuccess,
  props<{ embedParams: IEmbedParamsResponse }>(),
);

export const dashboardError = createAction(
  DashboardActionTypes.DashboardError,
  props<{ error: any }>(),
);

export const showErrors = createAction(
  DashboardActionTypes.ShowErrorMessage,
  props<{ message: string }>(),
);

export const setFilters = createAction(
  DashboardActionTypes.SetFilters,
  props<{ filters: any }>(),
);

export const setSelectedDashboard = createAction(
  DashboardActionTypes.setSelectedDashboard,
  props<{ dashboard: DASHBOARD_URL }>(),
);

export const setClearFilterHeader = createAction(
  DashboardActionTypes.SetClearFilterHeader,
  props<{ filterParam: FilterParam; valueToRemove: any }>(),
);

export const setOpenDashboardFilter = createAction(
  DashboardActionTypes.SetOpenDashboardFilter,
);

export const setActivePage = createAction(
  DashboardActionTypes.SetActivePage,
  props<{ page: DASHBOARD_PAGE }>(),
);
