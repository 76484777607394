import {
  ChangeDetectionStrategy,
  Component,
  computed,
  signal,
} from '@angular/core';
import { Store } from '@ngrx/store';

import {
  selectPopupVisible,
  selectResponse,
  selectSelectedFileImportType,
} from 'src/app/store/file-import/file-import.selector';
import { XlsxReaderService } from '@core/services/file-reader/xlsx-reader.service';

import {
  NzUploadChangeParam,
  NzUploadFile,
  NzUploadType,
  NzUploadXHRArgs,
  UploadFileStatus,
} from 'ng-zorro-antd/upload';
import { Subject, Subscription } from 'rxjs';
import {
  setFileData,
  showPopup,
  uploadSetFileData,
} from 'src/app/store/file-import/file-import.actions';
import { ImportTypeTitle } from '@shared/utility/global-enums-titles';
import { NotificationService } from '@core/services/notifications/notifications.service';
@Component({
  selector: 'xpw-file-import-popup',
  template: `
    <nz-modal [nzVisible]="isVisible$()" (nzOnCancel)="hidePopup()">
      <div style="width: calc(100% - 30px);" *nzModalTitle>
        <span>{{ title() }} </span>
      </div>

      <div *nzModalContent>
        <nz-upload
          [nzAccept]="'.csv,.xlsx'"
          [nzType]="uploadType()"
          [nzLimit]="1"
          [nzMultiple]="false"
          [nzCustomRequest]="onFileSelection"
          [(nzFileList)]="files"
          [nzFileListRender]="fileListTemplate"
          (nzChange)="handleFileChange($event)"
        >
          @if (!uploadingState()) {
            <span>
              <p class="ant-upload-drag-icon">
                <xpw-icon icon="inbox" />
              </p>
              <p class="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p class="ant-upload-hint">Files formats supported: XLSX.</p>
            </span>
          }
          <ng-template #fileListTemplate let-list>
            <nz-list nzBordered>
              @for (item of list; track item.uid) {
                <nz-list-item>
                  <span nz-typography>
                    @if (item.status == 'done') {
                      <ng-container>
                        <div class="file-item file-item-success">
                          <xpw-icon icon="file" type="twotone"></xpw-icon>
                          <div class="file-info">
                            <p class="file-name">{{ item.name }}</p>
                            <span class="file-error-label"
                              >{{ item?.size / 1000 }} kByte</span
                            >
                          </div>
                          <xpw-icon
                            icon="delete"
                            (click)="removeFile(item)"
                          ></xpw-icon>
                        </div>
                      </ng-container>
                    }

                    @if (item.status == 'error') {
                      <ng-container>
                        <div class="file-item file-item-error">
                          <xpw-icon icon="error-file" type="twotone"></xpw-icon>
                          <div class="file-info">
                            <p class="file-name">{{ item.name }}</p>
                            <span class="file-error-label">{{
                              detailFileInfo
                            }}</span>
                          </div>
                          <xpw-icon
                            icon="delete"
                            (click)="removeFile(item)"
                          ></xpw-icon>
                        </div>
                      </ng-container>
                    }
                    @if (item.status == 'uploading') {
                      <ng-container>
                        <span nz-icon [nzType]="'loading'"></span>
                      </ng-container>
                    }
                  </span>
                </nz-list-item>
              }
            </nz-list>
          </ng-template>
        </nz-upload>

        @if (uploadingState()) {
          <div class="ant-upload-list ant-upload-list-picture">
            <div class="flex-row align-center space-around card">
              <p>
                <label>Sheets</label>
                <span
                  ><xpw-icon icon="sheet" /> {{ sheetOptions().length }}</span
                >
              </p>
              <p>
                <label>Records</label>
                <span><xpw-icon icon="meter" /> {{ meterCount() }}</span>
              </p>
            </div>
            <div class="flex-row align-center">
              <div>Please choose the sheet you want to upload</div>
              <xpw-select
                [disabled]="sheetOptions().length === 0"
                [options]="sheetOptions()"
                [value]="selectedSheet()"
                (valueChange)="selectSheet($event)"
                style="width: 200px;display:block;"
              />
            </div>
          </div>
        }
      </div>
      <div *nzModalFooter>
        <button nz-button nzType="default" (click)="hidePopup()">Cancel</button>
        <button
          nz-button
          nzType="primary"
          (click)="handleContinue()"
          [disabled]="!isSubmitValid()"
          [nzLoading]="isConfirmLoading()"
        >
          Continue
        </button>
      </div>
    </nz-modal>
  `,
  styleUrls: ['./file-import-popup.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpwFileImportPopupComponent {
  isVisible$ = this.store.selectSignal(selectPopupVisible);

  selectedFileImportType = this.store.selectSignal(
    selectSelectedFileImportType,
  );
  title = computed(() => ImportTypeTitle[this.selectedFileImportType()]);

  meterCount = signal(0);
  isConfirmLoading = signal(false);
  detailFileInfo: string | null = null;
  uploadType = signal<NzUploadType>('drag');
  selectedSheet = signal<any>(null);
  files = signal<NzUploadFile[]>([]);
  uploadingState = signal(false);
  sheetOptions = signal<any[]>([]);
  private fileChangeSubject = new Subject();

  constructor(
    private store: Store,
    private xlsReader: XlsxReaderService,
    private notifications: NotificationService,
  ) {
    // Subscribing to store selectors
    this.subscribeToStore();
  }

  // Method to subscribe to store selectors
  private subscribeToStore(): void {
    this.store.select(selectResponse).subscribe((response) => {
      if (response) this.handleResponse(response);
    });
  }

  handleFileChange(info: NzUploadChangeParam): void {
    //check file amount and remove any extra filesexcept the first one
    if (info.fileList.length > 1) {
      this.files.set(info.fileList.slice(-1));
    }

    if (!this.checkFileType(info.file)) {
      info.file.status = 'error';
      this.detailFileInfo = $localize`Invalid file type. Please upload a CSV or XLSX file`;

      this.uploadType.set('drag');
      this.uploadingState.set(false);
      return;
    }
  }

  handleContinue(): void {
    this.isConfirmLoading.set(true);
    this.uploadFile();
  }

  hidePopup(): void {
    this.resetFileUpload();
    this.store.dispatch(showPopup({ isVisible: false }));
  }

  // Function to create sheet options
  createSheetOptions(data: { [key: string]: number }) {
    this.sheetOptions.set(
      Object.keys(data).map((key) => {
        return { label: key, value: data[key] + key, count: data[key] };
      }),
    );
  }

  // Function to handle file status
  handleFileStatus(file: NzUploadFile, item: NzUploadXHRArgs) {
    if (file.uid === item.file.uid) {
      if (this.sheetOptions().every((item) => item.count === 0)) {
        this.setFileStatus(file, 'error', $localize`File is empty`);
      } else {
        if (this.sheetOptions().length == 1) {
          this.selectSheet(this.sheetOptions()[0].value);
        }
        this.setFileStatus(file, 'done');
      }
    }
  }

  // Function to set file status and handle notifications
  setFileStatus(
    file: NzUploadFile,
    status: UploadFileStatus,
    message?: string,
  ) {
    file.status = status;
    this.detailFileInfo = message || null;

    switch (status) {
      case 'done':
        this.uploadType.set('select');
        this.uploadingState.set(true);
        break;
      case 'error':
        this.uploadType.set('drag');
        this.uploadingState.set(false);
        this.detailFileInfo =
          this.detailFileInfo ??
          $localize`${file.name} file local read failed.`;
        break;
    }
  }

  // Refactored readFileResponse function
  readFileResponse = (
    data: { [key: string]: number },
    item: NzUploadXHRArgs,
  ) => {
    this.createSheetOptions(data);
    this.files().forEach((file) => this.handleFileStatus(file, item));
    this.fileChangeSubject.next(item.file);
  };

  onFileSelection: (item: NzUploadXHRArgs) => Subscription = (
    item: NzUploadXHRArgs,
  ) => {
    this.uploadingState.set(true);
    this.uploadType.set('select'); // this line is just to remove the border of the upload box;

    if (this.checkFileType(item.file)) {
      this.xlsReader.processFile(item.file, (data) =>
        this.readFileResponse(data, item),
      );
    }
    return this.fileChangeSubject.subscribe();
  };

  selectSheet = (sheetNameValue: number) => {
    const sheet = this.sheetOptions().find(
      (options) => options.value === sheetNameValue,
    );
    this.selectedSheet.set(sheet.value);

    this.meterCount.set(sheet.count);

    this.store.dispatch(
      setFileData({
        sheetName: sheet.label,
      }),
    );
  };

  uploadFile = () => {
    this.store.dispatch(
      uploadSetFileData({ file: this.files()[0].originFileObj }),
    );
  };

  checkFileType = (file: NzUploadFile) => {
    const fileType = file.name.split('.').pop().toLocaleLowerCase();
    if (fileType !== 'csv' && fileType !== 'xlsx') {
      return false;
    }
    return true;
  };
  removeFile = (file: NzUploadFile) => {
    this.files.update((files) => files.filter((f) => f.uid !== file.uid));
    if (this.files().length === 0) {
      this.uploadingState.set(false);
      this.uploadType.set('drag');
    }
  };

  handleResponse = (response: any) => {
    this.isConfirmLoading.set(false);
    if (response.fileImportUID) {
      this.notifications.loading(
        undefined,
        $localize`File uploaded successfully and starting processing`,
      );
      this.hidePopup();
    } else {
      this.files()[0].status = 'error';
      this.uploadingState.set(false);
      this.uploadType.set('drag');
      this.detailFileInfo = response.message;
      this.selectedSheet.set(null);
      if (response.status == 500)
        this.detailFileInfo = $localize`An error occurred while uploading the file. Please try again.`;
    }
  };

  isSubmitValid = (): boolean => {
    return (
      this.files().length > 0 &&
      this.selectedSheet() !== null &&
      this.meterCount() > 0
    );
  };

  resetFileUpload = () => {
    this.files.set([]);
    this.uploadingState.set(false);
    this.uploadType.set('drag');
    this.sheetOptions.set([]);
    this.selectedSheet.set(null);
    this.meterCount.set(0);
  };
}
