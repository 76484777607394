import { createAction, props } from '@ngrx/store';
import { IMeterGroup, IMeterGroupView } from './meter-group.interface';
import { saveButton } from './meter-group.state';

export enum MeterGroupsActionType {
  setSelectedGroup = '[MeterGroup] Select MeterGroup',
  getMetersList = '[MeterGroup] Get meters List',
  getMetersListSuccess = '[MeterGroup] Get meters List Success',
  getMetersListFailure = '[MeterGroup] Get meters List Failure',
  getGroupList = '[MeterGroup] Get MeterGroup List',
  getGroupListSuccess = '[MeterGroup] Get MeterGroup List Success',
  updateGroup = '[MeterGroup] Save edited MeterGroup',
  updateGroupSuccess = '[MeterGroup] Save edited MeterGroup success',
  updateGroupFailure = '[MeterGroup] Save edited MeterGroup failure',
  setNewGroup = '[MeterGroup] Create new MeterGroup',
  setNewChildGroup = '[MeterGroup] Create child new MeterGroup',
  createNewGroup = '[MeterGroup] Save new MeterGroup',
  createNewGroupSuccess = '[MeterGroup] Save new MeterGroup success',
  createNewGroupFailure = '[MeterGroup] Save new MeterGroup failure',
  deleteGroup = '[MeterGroup] Delete MeterGroup',
  deleteGroupSuccess = '[MeterGroup] Delete MeterGroup success',
  deleteGroupFailure = '[MeterGroup] Delete MeterGroup failure',
  ShowErrorMessage = '[Meter] Show Errors Messages',
  ShowMetersGroupSuccessMessage = '[Meter] Show Messages Success',
  ValidationErrors = '[MeterGroup] Validation Errors',

  ResetMeterGroupState = '[MeterGroup] Reset MeterGroup State',
  SearchChange = '[MeterGroup] Search Change',

  selectMeterByName = '[MeterGroup] Select allmeter by name filter',
  unselectMeterByName = '[MeterGroup] UnSelect allmeter by name filter',
  updateSelectedMeterUIDs = '[Meter Group] Update Selected Meter UIDs',
  setLastChangedCreatedMeterGroup = '[Meter Group] Save last meter group',

  setMeterUIDsToAdd = '[Meter Group] Set Meter UIDs to Add',
  setMeterUIDsToRemove = '[Meter Group] Set Meter UIDs to Remove',

  setSaveButton = '[Meter Group] Set Save Button',
  updateMeterGroupMetersList = '[Meter Group] Update MeterGroup Meters List',
  updateMeterGroupMetersListSuccess = '[Meter Group] Update MeterGroup Meters List Success',
}

export const setSelectedGroup = createAction(
  MeterGroupsActionType.setSelectedGroup,
  props<{ meterGroupUID: string; newMeterGroup: boolean }>(),
);

export const selectMeterByName = createAction(
  MeterGroupsActionType.selectMeterByName,
  props<{ name: string }>(),
);
export const unselectMeterByName = createAction(
  MeterGroupsActionType.unselectMeterByName,
  props<{ name: string }>(),
);

export const updateSelectedMeterUIDs = createAction(
  MeterGroupsActionType.updateSelectedMeterUIDs,
  props<{ meterUIDs: string[] }>(),
);

// MeterGroup list
export const getGroupList = createAction(MeterGroupsActionType.getGroupList);

export const getGroupListSuccess = createAction(
  MeterGroupsActionType.getGroupListSuccess,
  props<{ meterGroups: IMeterGroupView[] }>(),
);

// Meters list
export const getMetersList = createAction(
  MeterGroupsActionType.getMetersList,
  props<{ meterGroup: IMeterGroup }>(),
);

export const getMetersListSuccess = createAction(
  MeterGroupsActionType.getMetersListSuccess,
  props<{ meterUIDs: string[] }>(),
);

// Save or update edited MeterGroup
export const updateGroup = createAction(
  MeterGroupsActionType.updateGroup,
  props<{ MeterGroupUpdated: IMeterGroup }>(),
);
export const updateGroupSuccess = createAction(
  MeterGroupsActionType.updateGroupSuccess,
);

// add New MeterGroup

export const setNewGroup = createAction(MeterGroupsActionType.setNewGroup);
export const setNewChildGroup = createAction(
  MeterGroupsActionType.setNewChildGroup,
  props<{ parentMeterGroupUID: string }>(),
);

export const createNewGroup = createAction(
  MeterGroupsActionType.createNewGroup,
  props<{ MeterGroupUpdated: IMeterGroup }>(),
);

export const createNewGroupSuccess = createAction(
  MeterGroupsActionType.createNewGroupSuccess,
  props<{ MeterGroupCreated: IMeterGroupView }>(),
);

//save last meter group

export const setLastChangedCreatedMeterGroup = createAction(
  MeterGroupsActionType.setLastChangedCreatedMeterGroup,
  props<{ meterGroup: IMeterGroup }>(),
);

//delete MeterGroup
export const deleteGroup = createAction(
  MeterGroupsActionType.deleteGroup,
  props<{ meterGroupUID: string }>(),
);

export const showErrors = createAction(
  MeterGroupsActionType.ShowErrorMessage,
  props<{ message: string }>(),
);

export const showMetersGroupSuccessMessage = createAction(
  MeterGroupsActionType.ShowMetersGroupSuccessMessage,
  props<{ message: string }>(),
);

export const validationError = createAction(
  MeterGroupsActionType.ValidationErrors,
  props<{ ValidationErrors: any }>(),
);

export const resetMeterGroupState = createAction(
  MeterGroupsActionType.ResetMeterGroupState,
);

export const searchChange = createAction(
  MeterGroupsActionType.SearchChange,
  props<{ search: string }>(),
);

export const setMeterUIDsToAdd = createAction(
  MeterGroupsActionType.setMeterUIDsToAdd,
  props<{ meterUIDs: string[] }>(),
);

export const setMeterUIDsToRemove = createAction(
  MeterGroupsActionType.setMeterUIDsToRemove,
  props<{ meterUIDs: string[] }>(),
);

export const updateMeterGroupMetersList = createAction(
  MeterGroupsActionType.updateMeterGroupMetersList,
);

export const updateMeterGroupMetersListSuccess = createAction(
  MeterGroupsActionType.updateMeterGroupMetersListSuccess,
  props<{ response: any }>(),
);

export const setSaveButton = createAction(
  MeterGroupsActionType.setSaveButton,
  props<{ saveButton: saveButton }>(),
);
