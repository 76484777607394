import { createReducer, on } from '@ngrx/store';
import * as MeterActions from './meters.actions';
import { initialMeterState } from './meters.state';

// IMeterState interface
export const MeterReducer = createReducer(
  initialMeterState,
  on(MeterActions.getMeterList, (state, { pagingRequest }) => ({
    ...state,
    meters: {
      ...state.meters,
      items: [],
      isLoading: true,
    },
    pagingRequest: pagingRequest,
  })),
  on(MeterActions.getMeterListSuccess, (state, { meters }) => ({
    ...state,
    meters: meters,
  })),
  on(MeterActions.getFilterOptionsSuccess, (state, { filterOptions }) => ({
    ...state,
    filterOptions: filterOptions,
  })),
  on(MeterActions.getMeterNameListSuccess, (state, { meterNames }) => ({
    ...state,
    meterNameList: meterNames,
  })),
  on(MeterActions.selectMeter, (state, { meterUID }) => ({
    ...state,
    selectedMeter:
      state.meters.items.find((item) => item.meterUID === meterUID) ||
      initialMeterState.selectedMeter,
    isNewMeter: false,
    drawersState: {
      ...state.drawersState,
      meterEditDrawer: false,
    },
  })),
  on(MeterActions.closeMeterDrawer, (state) => ({
    ...state,
    drawersState: {
      ...state.drawersState,
      meterEditDrawer: true,
    },
  })),
  on(MeterActions.createMeterSuccess, (state, { createdMeter }) => ({
    ...state,
    meters: {
      ...state.meters,
      items: [createdMeter, ...state.meters.items],
      pagingInfo: {
        ...state.meters.pagingInfo,
        totalCount: state.meters.pagingInfo.totalCount + 1,
      },
    },

    selectedMeter: createdMeter,
    isNewMeter: false,
  })),
  on(MeterActions.updateMeterSuccess, (state, { updatedMeter }) => ({
    ...state,
    meters: {
      ...state.meters,
      items: state.meters.items.map((item) =>
        item.meterUID === updatedMeter.meterUID ? updatedMeter : item,
      ),
    },
  })),
  on(MeterActions.deleteMeterSuccess, (state, { meterUID }) => ({
    ...state,
    meters: {
      ...state.meters,
      items: state.meters.items.filter((item) => item.meterUID !== meterUID),
      pagingInfo: {
        ...state.meters.pagingInfo,
        totalCount: state.meters.pagingInfo.totalCount - 1,
      },
    },

    selectedMeter: initialMeterState.selectedMeter,
  })),
  on(MeterActions.closeReplacementDrawer, (state) => ({
    ...state,
    drawersState: {
      ...state.drawersState,
      replacementEditDrawer: true,
    },
  })),
  on(MeterActions.selectNewMeter, (state) => ({
    ...state,
    selectedMeter: initialMeterState.selectedMeter,
    isNewMeter: true,
    drawersState: {
      ...state.drawersState,
      meterEditDrawer: false,
    },
  })),
  on(MeterActions.validationError, (state, { ValidationErrors }) => {
    return {
      ...state,
      ValidationErrors,
    };
  }),

  on(MeterActions.selectReplacement, (state, { replacementUID }) => {
    return {
      ...state,
      replacementHistory: {
        ...state.replacementHistory,
        isNewReplacement: false,
        selectedReplacement: state.replacementHistory.items.find(
          (x) => x.meterReplacementUID == replacementUID,
        ),
      },
      drawersState: {
        ...state.drawersState,
        replacementEditDrawer: false,
      },
    };
  }),
  on(MeterActions.selectNewReplacement, (state) => {
    return {
      ...state,
      replacementHistory: {
        ...state.replacementHistory,
        selectedReplacement:
          initialMeterState.replacementHistory.selectedReplacement,
        isNewReplacement: true,
      },
      drawersState: {
        ...state.drawersState,
        replacementEditDrawer: false,
      },
    };
  }),
  on(
    MeterActions.getReplacementHystorySuccess,
    (state, { replacementList }) => {
      return {
        ...state,
        replacementHistory: {
          ...state.replacementHistory,
          items: replacementList,
        },
      };
    },
  ),
  on(MeterActions.filterChange, (state, { filter }) => ({
    ...state,
    filter: {
      MeterUID: filter?.MeterUID || [],
      MeterGroupUID: filter?.MeterGroupUID || [],
      UtilityServiceId: filter?.UtilityServiceId || [],
      MeasuringPointTypeId: filter?.MeasuringPointTypeId || [],
      PowerSourceUID: filter?.PowerSourceUID || [],
      IsVirtual: filter?.IsVirtual || [],
      IsOppositeConfiguration: filter?.IsOppositeConfiguration || [],
      StateId: filter?.StateId || [],
      City: filter?.City || [],
      ChannelConfigurationUID: filter?.ChannelConfigurationUID || [],
      StatusID: filter?.StatusID || [],
    },
  })),
  on(MeterActions.searchChange, (state, { search }) => ({
    ...state,

    search: search,
  })),
  on(MeterActions.resetMeterFilterAndSearch, (state) => ({
    ...state,
    filter: initialMeterState.filter,
    search: '',
    meters: {
      items: initialMeterState.meters.items,
      pagingInfo: initialMeterState.meters.pagingInfo,
      isLoading: initialMeterState.meters.isLoading,
    },
  })),
  on(MeterActions.resetMeterState, (state) => ({
    ...state,
    ...initialMeterState,
  })),
);
