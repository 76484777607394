import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  input,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputBooleanComponent } from '../abstract-input-boolean.component';
import { NzTSType } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'xpw-form-input-radio-button',
  template: `
    <nz-form-control
      [nzHasFeedback]="true"
      [nzValidateStatus]="errorStatus()"
      [nzErrorTip]="getErrorLabel()"
    >
      <nz-radio-group [(ngModel)]="value">
        @for (option of options(); track option) {
          <label nz-radio [nzValue]="option.value">{{ option.label }}</label>
        }
        <!-- <label nz-radio nzValue="B">B</label>
      <label nz-radio nzValue="C">C</label>
      <label nz-radio nzValue="D">D</label> -->
      </nz-radio-group>
    </nz-form-control>
  `,
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwInputRadioButtonComponent),
      multi: true,
    },
  ],
})
export class XpwInputRadioButtonComponent extends AbstractInputBooleanComponent {
  @Input() label: string = '';
  @Input() suffixIcon: string | null = null;
  @Input() suffixIconTooltipTitle: string | NzTSType | null = null;
  @Input() isDisabled: boolean = false;
  options = input<{ value: string; label: string }[]>();
}
