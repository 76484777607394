import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileManagmentService {
  apiBaseUrl = '';
  endpoint = 'fileManagement'; // Name form API

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  uploadFile(file: File): Observable<any> {
    const formData = new FormData();

    formData.append('file', file as Blob);

    return this.http.post(
      `${this.apiBaseUrl}${this.endpoint}/upload`,

      formData,
    );
  }
}
