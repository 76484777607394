import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Signal,
  computed,
  effect,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectCurrentRoute,
  selectRouteParams,
  selectUrl,
} from 'src/app/store/router/router.selectors';
import {
  selectCurrentUserProperties,
  selectCurrentUserRoles,
  selectLoggedIn,
} from 'src/app/store/current-user/current-user.selector';
import { ILoginData } from 'src/app/store/current-user/current-user.interface';
import { CurrentUserFlowService } from '@core/services/current-user/current-user.service';
import { Observable } from 'rxjs';
import { Role } from '@features/user/user-store/user.interface';
import { Navigation } from './main-nav.state';
import { Router } from '@angular/router';
import { setSelectedDashboard } from '@features/dashboard/store/dashboard.actions';

// import { XpwMenuItemComponent } from './menu-item.component';

export interface menu {
  id?: number;
  title: string;
  url?: string;
  children?: menu[];
  icon?: string;
  selected?: boolean;
  open?: boolean;
  viewForRole?: Role[];
  disabled?: boolean;
}

@Component({
  selector: 'xpw-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.less'],
})
export class MainNavComponent implements OnInit {
  @Input() isCollapsed: boolean;
  @Output() Collapsed = new EventEmitter<boolean>();
  routerURL: Signal<any>;
  isLoggedIn: Signal<ILoginData>;
  accountName: string = ''; // temp var for check Active user in MSAL
  currentRoute: string = '';
  feature: string = '';
  page: string = '';

  currentUserData$: Observable<any>;
  currentUserRoles: Role[] = [];
  constructor(
    private router: Router,
    public currentUserFlow: CurrentUserFlowService,
    private store: Store,
  ) {
    effect(() => {
      this.mainNavigation.map((item) => {
        item.open = false;
        if (item.children) {
          item.children.map((child) => {
            if (`/${child.url}` == this.routerURL()) {
              child.selected = true;
              item.open = true;
            } else {
              child.selected = false;
            }
          });
        }
      });
    });
    this.isLoggedIn = store.selectSignal(selectLoggedIn);
  }

  ngOnInit() {
    this.routerURL = this.store.selectSignal(selectUrl);
    this.currentUserData$ = this.store.select(selectCurrentUserRoles);
    // when router has information about the current route
    // console.log('this.router', this.router());
    this.currentUserData$.subscribe((roles) => {
      this.currentUserRoles = roles;
    });
  }
  mainNavigation: menu[] = Navigation;

  changeCollapse() {
    this.Collapsed.emit(!this.isCollapsed);
  }

  navigate(link: any) {
    if (link == undefined) return;
    this.router.navigateByUrl(link);
  }
  shouldView(menuItem: menu) {
    if (!menuItem.viewForRole) {
      return true;
    }
    return menuItem.viewForRole.some((role) =>
      this.currentUserRoles.includes(role),
    );
  }
  logout() {
    this.currentUserFlow.logout();
  }
  onItemNavOpenChange(item: menu): void {
    this.mainNavigation.forEach((i) => {
      i.open = false;
    });
    item.open = true;
  }
}
