import { Component, computed, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/api/authentication.service';
import { DrawService } from '@core/services/popup/drawer.service';
import { PopupService } from '@core/services/popup/popup.modal.service';
import { Store } from '@ngrx/store';
import { selectLoggedIn } from 'src/app/store/current-user/current-user.selector';

@Component({
  selector: 'xpw-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less'],
})
export class LayoutComponent implements OnInit {
  isCollapsed = false;
  isOpen = true;
  // isAuthorized = false;
  constructor(
    private viewContainerRef: ViewContainerRef,
    private drawerService: DrawService,
    // private modalService: PopupService,
    private store: Store,
  ) {
    this.drawerService.setViewContainerRef(this.viewContainerRef);
    // this.modalService.setViewContainerRef(this.viewContainerRef);
  }
  loginDate = this.store.selectSignal(selectLoggedIn);
  isAuthorized = computed(() => this.loginDate().isLoggedIn);
  ngOnInit() {
    this.isOpen = false;
  }
}
