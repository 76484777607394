import { createReducer, on } from '@ngrx/store';
import * as DashboardActions from './dashboard.actions';
import { initialDashboardState } from './dashboard.state';
import {
  DASHBOARD_PAGE,
  DASHBOARD_URL,
  FilterParam,
  IDashboardPage,
  IDashboardParams,
  IDashboardSlicers,
} from './dashboard.interface';

export const dashboardReducer = createReducer(
  initialDashboardState,

  on(DashboardActions.getEmbedParamsSuccess, (state, { embedParams }) => ({
    ...state,
    embedParams: embedParams,
    isLoading: false,
  })),
  on(DashboardActions.setOpenDashboardFilter, (state) => ({
    ...state,
    shouldCloseDashboard: false,
  })),
  on(DashboardActions.setSelectedDashboard, (state, { dashboard }) => ({
    ...state,
    shouldCloseDashboard: false,
    activePage: setActivePage(state.pagesActivity, dashboard, null),
    selectedDashboard: {
      url: dashboard,
      title: state.dashboardParams.find((param) => param.value === dashboard)
        ?.title,
      waitingForData:
        state.dashboardParams.find(
          (param) =>
            param.value === dashboard &&
            param.filters.includes(FilterParam.Meters),
        ) && state.filters[FilterParam.Meters].length === 0,
    },
    selectedDashboardSlicers: getSelectedDashboardSlicers(
      dashboard,
      state.dashboardParams,
      state.dashboardSlicers,
      state.filters,
    ),
  })),
  on(DashboardActions.setActivePage, (state, { page }) => ({
    ...state,
    activePage: setActivePage(
      state.pagesActivity,
      state.selectedDashboard?.url,
      page,
    ),
  })),
  on(DashboardActions.setFilters, (state, { filters }) => ({
    ...state,
    shouldCloseDashboard: true,
    filters: {
      ...state.filters,
      [FilterParam.Date_Range]: filters[FilterParam.Date_Range],
      [FilterParam.Meter_Group]: filters[FilterParam.Meter_Group],
      [FilterParam.Meters]: filters[FilterParam.Meters],
      [FilterParam.Tempeture_Unit]: filters[FilterParam.Tempeture_Unit],
      [FilterParam.Energy_Unit]: filters[FilterParam.Energy_Unit],
      [FilterParam.Demand_Unit]: filters[FilterParam.Demand_Unit],
    },
    selectedDashboard: {
      ...state.selectedDashboard,
      waitingForData:
        state.selectedDashboard?.waitingForData &&
        filters[FilterParam.Meters].length === 0,
    },
    selectedDashboardSlicers: getSelectedDashboardSlicers(
      state.selectedDashboard?.url,
      state.dashboardParams,
      state.dashboardSlicers,
      filters,
    ),
  })),
  on(
    DashboardActions.setClearFilterHeader,
    (state, { filterParam, valueToRemove }) => ({
      ...state,

      filters: {
        ...state.filters,
        [filterParam]: clearFilterValues(
          state.selectedDashboardSlicers,
          filterParam,
          valueToRemove,
        ),
      },
      selectedDashboardSlicers: clearFilterHeader(
        state.selectedDashboardSlicers,
        filterParam,
        valueToRemove,
      ),
    }),
  ),
);
function setActivePage(
  statePages: any,
  dashboard: DASHBOARD_URL,
  page: string | null,
): IDashboardPage {
  if (!page) return null;
  return page
    ? statePages[dashboard].pages.find((p) => p.displayName === page)
    : statePages[dashboard].pages.find((page) => page.isMain);
}
function clearFilterValues(
  selectedDashboardSlicers: { [key in FilterParam]: IDashboardSlicers },
  filterParam: FilterParam,
  valueToRemove: any,
) {
  const slicerToUpdate = selectedDashboardSlicers[filterParam];
  if (slicerToUpdate) {
    if (valueToRemove) {
      return slicerToUpdate.params.filter((p) => p !== valueToRemove);
    } else {
      return [];
    }
  }

  return selectedDashboardSlicers[filterParam]
    ? null
    : selectedDashboardSlicers;
}
function clearFilterHeader(
  selectedDashboardSlicers: { [key in FilterParam]: IDashboardSlicers },
  filterParam: FilterParam,
  valueToRemove: any,
) {
  // check if there is meters or meter groups and clear the params, return the rest as is
  let updatedSlicers = { ...selectedDashboardSlicers };
  let slicerToUpdate = updatedSlicers[filterParam];

  if (slicerToUpdate) {
    if (valueToRemove) {
      slicerToUpdate = {
        ...slicerToUpdate,
        params: slicerToUpdate.params.filter((p) => p !== valueToRemove),
      };
    } else {
      slicerToUpdate = { ...slicerToUpdate, params: [] };
    }

    updatedSlicers = {
      ...updatedSlicers,
      [filterParam]: slicerToUpdate,
    };
  }

  return updatedSlicers;
}
function getSelectedDashboardSlicers(
  selectedDashboard: DASHBOARD_URL,
  dashboardParams: IDashboardParams[],
  dashboardSlicers: { [key in FilterParam]: IDashboardSlicers },
  filters: any,
): { [key in FilterParam]: IDashboardSlicers } {
  let selectedDashboardSlicers: any = {};

  // create an array of slicers that match the selected dashboard filters, and attach the filter values to params
  const dashboardFilters = dashboardParams.find(
    (param) => param.value === selectedDashboard,
  )?.filters;
  if (!dashboardFilters) return null;
  for (const filter of dashboardFilters) {
    const slicer = dashboardSlicers[filter];
    if (slicer) {
      let value: any;
      if (filter === FilterParam.Date_Range) {
        const [startDate, endDate] = filters[FilterParam.Date_Range];
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        value = [startDate.toISOString(), endDate.toISOString()];
      } else {
        value = filters[slicer.filterParam];
      }

      selectedDashboardSlicers[filter] = {
        ...slicer,
        params: value,
      };
    }
  }

  return selectedDashboardSlicers as {
    [key in FilterParam]: IDashboardSlicers;
  };
}
