import { Component, Input, OnInit } from '@angular/core';
import { MeterStatus } from '@features/meters/store/meters.interface';

@Component({
  selector: 'xpw-status-lamp',
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      [style.fill]="color"
    >
      <path
        d="M13.5 9C13.5 11.4853 11.4853 13.5 9 13.5C6.51472 13.5 4.5 11.4853 4.5 9C4.5 6.51472 6.51472 4.5 9 4.5C11.4853 4.5 13.5 6.51472 13.5 9Z"
      />
    </svg>
  `,
})
export class XpwStatusLampComponent implements OnInit {
  @Input() statusId: number | null = null;
  color: string = '#F5F5F5';

  ngOnInit(): void {
    this.updateColor();
  }

  ngOnChanges(): void {
    this.updateColor();
  }

  private updateColor(): void {
    switch (this.statusId) {
      case MeterStatus.Active:
        this.color = '#52C41A';
        break;
      case MeterStatus.Suspended:
        this.color = '#f0f0f0';
        break;
      case MeterStatus.Stopped:
        this.color = 'red';
        break;
      case MeterStatus.Deleted:
        this.color = 'yellow';
        break;
      default:
        this.color = '#F5F5F5';
    }
  }
}
