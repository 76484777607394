import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  ReportConfigStatus,
  ReportStatus,
} from '@features/reports/store/reports.interface';

@Component({
  selector: 'xpw-report-status',
  template: `
    <nz-tag [nzColor]="color" nz-tooltip [nzTooltipTitle]="tooltip">
      <xpw-icon [icon]="icon" />
      <span>{{ stringTitle }}</span>
    </nz-tag>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpwReportStatusTagComponent implements OnChanges {
  @Input() statusId: ReportStatus = null;
  stringTitle: string = '';
  icon: string | null = null;
  color: string = 'default';
  tooltip: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['statusId']) {
      this.getData();
    }
  }
  getData() {
    switch (this.statusId) {
      case ReportStatus.Generating:
        this.stringTitle = $localize`Waiting...`;
        this.color = 'default';
        this.icon = 'clock-circle';
        this.tooltip = $localize`Generating...`;
        break;
      case ReportStatus.FailedToGenerate:
        this.stringTitle = $localize`Failed`;
        this.color = 'red';
        this.icon = 'failed';
        this.tooltip = $localize`Failed to Generate`;
        break;
      case ReportStatus.Delivering:
        this.stringTitle = $localize`Waiting...`;
        this.color = 'default';
        this.icon = 'clock-circle';
        this.tooltip = $localize`Delivering...`;
        break;
      case ReportStatus.FailedToDeliver:
        this.stringTitle = $localize`Failed`;
        this.color = 'red';
        this.icon = 'failed';
        this.tooltip = $localize`Failed to Deliver`;
        break;
      case ReportStatus.Delivered:
        this.stringTitle = $localize`Success`;
        this.color = 'green';
        this.icon = 'check';
        this.tooltip = $localize`Delivered`;
        break;
      default:
        this.stringTitle = $localize`Unknown`;
        this.tooltip = $localize`Unknown`;
        break;
    }
  }
}
