import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';
import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'xpw-form-input-strings-list',
  template: `
    <nz-form-item>
      <nz-form-label
        >{{ label }} {{ isOptional ? ' (Optional)' : '' }}
      </nz-form-label>
      <nz-form-control
        [nzHasFeedback]="false"
        [nzValidateStatus]="errorStatus()"
        [nzErrorTip]="getErrorLabel()"
      >
        <div
          class="input-button-container"
          *ngFor="let item of stringsValues; let i = index"
        >
          <input
            #inputElement
            type="text"
            nz-input
            [value]="item"
            [placeholder]="placeholder + ' ' + (i + 1)"
            (input)="onInput(i, $event)"
          />

          <xpw-button type="link" (click)="deleteValue(i)">
            <xpw-icon icon="delete" />
          </xpw-button>
        </div>
        <xpw-button type="link" (click)="addValue()">
          <xpw-icon icon="add-plus" />
        </xpw-button>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: [
    `
      .ant-form-vertical nz-form-label.ant-form-item-label {
        padding: 0 0 4px 0;
      }
      .input-button-container {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwInputListOfStringsComponent),
      multi: true,
    },
  ],
})
export class XpwInputListOfStringsComponent extends AbstractInputComponent {
  @ViewChildren('inputElement') inputElements: QueryList<ElementRef>;
  @Input('label') label: string;

  stringsValues: string[] = [];

  private inputEvents = new Subject<{ index: number; value: string }>();

  ngOnInit() {
    // Subscribe to the inputEvents subject
    // Use the debounceTime operator to wait for 300ms of inactivity before emitting the latest value
    this.inputEvents
      .pipe(
        debounceTime(300), // Adjust time as needed
      )
      .subscribe(({ index, value }) => {
        this.inputStringValue(index, value);
      });
  }

  ngOnDestroy() {
    // Ensure to clean up the subscription to prevent memory leaks
    this.inputEvents.unsubscribe();
  }

  onInput(index: number, event: any) {
    // Emit the input event along with its current value to the subject
    this.inputEvents.next({ index, value: event.target.value });

    // Set focus back to the current input element
    setTimeout(() => {
      const inputArray = this.inputElements.toArray();
      if (inputArray[index]) {
        inputArray[index].nativeElement.focus();
      }
    }, 400);
  }

  override writeValue(value: string): void {
    console.log('writeValue', value);
    this.stringsValues = this.convertJsonToList(value);
  }

  changeValue() {
    this.onChange(this.convertListToJson(this.stringsValues));
  }

  inputStringValue(index: number, value: any) {
    this.stringsValues = [
      ...this.stringsValues.slice(0, index),
      value,
      ...this.stringsValues.slice(index + 1),
    ];
    this.changeValue();
  }

  addValue() {
    this.stringsValues.push('');
  }

  deleteValue(index: number) {
    this.stringsValues.splice(index, 1);
    this.changeValue();
  }

  convertJsonToList(jsonString: string): string[] {
    try {
      const parsedArray: string[] = JSON.parse(jsonString);
      if (
        Array.isArray(parsedArray) &&
        parsedArray.every((item) => typeof item === 'string')
      ) {
        return parsedArray;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }

  convertListToJson(listOfStrings: string[]): string {
    try {
      if (
        Array.isArray(listOfStrings) &&
        listOfStrings.every((item) => typeof item === 'string')
      ) {
        return JSON.stringify(listOfStrings);
      } else {
        return '';
      }
    } catch (error) {
      console.error('Error converting list to JSON string:', error);
      return '';
    }
  }
}
