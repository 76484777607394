import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IEmbedParamsResponse } from '@features/dashboard/store/dashboard.interface';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  apiBaseUrl = '';
  endpoint = 'dashboard'; // Name form API

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  getEmbedParams(): Observable<IEmbedParamsResponse> {
    return this.http.get<IEmbedParamsResponse>(
      this.apiBaseUrl + this.endpoint + '/embedParams',
    );
  }
}
