import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { IMeter, MeterNameList } from '@features/meters/store/meters.interface';
import { selectMeterNameList } from '@features/meters/store/meters.selector';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'GetMeterName',
})
export class XpwGetMeterName implements PipeTransform, OnDestroy {
  private meterList: MeterNameList;
  private subscription: Subscription;

  constructor(private store: Store) {
    this.subscription = this.store
      .pipe(select(selectMeterNameList))
      .subscribe((meterList) => {
        this.meterList = meterList;
      });
  }

  transform(meterUid: string): string {
    if (!this.meterList || !meterUid) return '';
    const meter = this.meterList.items.find((item) => item.uid === meterUid);
    return meter?.name ?? '';
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
