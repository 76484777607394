// src/app/user/state/dashboard.effects.ts

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as DashboardActions from './dashboard.actions';

import { DashboardService } from '@core/api/dashboard.service';
import { NotificationService } from '@core/services/notifications/notifications.service';
import { Store } from '@ngrx/store';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private apiService: DashboardService,
    private notification: NotificationService,
    private store: Store,
  ) {}

  getEmbedParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.getEmbedParams),
      mergeMap(() =>
        this.apiService.getEmbedParams().pipe(
          map((embedParamsResponse) => {
            return embedParamsResponse;
          }),
          map((embedParams) =>
            DashboardActions.getEmbedParamsSuccess({ embedParams }),
          ),
          catchError((error) => of(DashboardActions.dashboardError({ error }))),
        ),
      ),
    ),
  );

  showErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.showErrors),
        tap((action) => {
          this.notification.error(undefined, action.message);
        }),
      ),
    { dispatch: false },
  );
}
